<template>
  <v-chip
    pill
    class="mr-4 mb-4"
  >
    <v-avatar left>
      <v-img v-if="avatarUrl" :src="avatarUrl"></v-img>
      <v-icon v-if="!avatarUrl">fa fa-user</v-icon>
    </v-avatar>

    <span>{{name}}</span>
  </v-chip>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    siteAdmin: Object,
  },
  computed: {
    avatarUrl() {
      return this.$helper.getPhotoUrl(this.siteAdmin.avatar)
    },
    name() {
      return this.$helper.getSiteUserName(this.siteAdmin)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>